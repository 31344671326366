import * as React from "react";
import { SRLWrapper } from "simple-react-lightbox";

import './images.css';

interface Props {
    images: IImage[];
}

interface State { }

export class Images extends React.Component<Props, State> {
    images: IImage[] = [];

    constructor(props: Props) {
        super(props);

        props.images.forEach(
            val => this.images.push(Object.assign({}, val))
        );
    }

    getCell(image: IImage | undefined) {
        if (image === undefined) {
            return <td />
        }

        return <td>
            <a key={image.name} href={image.full}>
                <img src={image.thumb} alt={image.name} width="90" />
            </a>
        </td>
    }

    getRow() {
        return <tr key={this.images.length}>
            {this.getCell(this.images.shift())}
            {this.getCell(this.images.shift())}
        </tr>
    }

    getTable() {
        let rows: any[] = []
        while (this.images.length > 0) {
            rows.push(this.getRow())
        }
        return <table><tbody>{rows}</tbody></table>
    }

    render() {
        return <SRLWrapper>{this.getTable()}</SRLWrapper>
    }
}
