import * as React from "react";
import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import SimpleReactLightbox from 'simple-react-lightbox'
import Layout from "./layout/index";
import Home from "./routes/home";
import Biografie from "./routes/biografie";
import Beelden from "./routes/beelden";
import Beeld from "./routes/beeld";
import Exposities from "./routes/exposities";
import Expositie from "./routes/expositie";

const rootElement = document.getElementById("root");

interface Props { }
interface State { }

export class Index extends React.Component<Props, State> {
  render() {
    return <SimpleReactLightbox>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="biografie" element={<Biografie />} />
            <Route path="beelden" element={<Beelden />} />
            <Route path="beeld/:beeldId" element={<Beeld />} />
            <Route path="exposities" element={<Exposities />} />
            <Route path="expositie/:expositieId" element={<Expositie />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </SimpleReactLightbox>
  }
}

render(<Index />, rootElement);