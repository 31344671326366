import * as React from "react";
import ReactMarkdown from 'react-markdown'
import { useParams } from "react-router-dom";
import { Images } from "../images/images"
import { sculptureService } from "../services/sculptures";


interface Props {
    beeldId: string
}

interface State {
    beeld: IBeeld | undefined
}


class Component extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            beeld: undefined
        }
    }

    update(beeld: IBeeld) {
        this.setState({
            beeld: beeld
        })
    }

    componentDidMount() {
        sculptureService.getSculpture(this.props.beeldId).then(response => {
            this.setState({ beeld: response })
        });
    }

    render() {
        if (this.state.beeld === undefined) {
            return <div>not found</div>
        }

        let beeld = this.state.beeld

        return <main style={{ padding: "1rem" }}>

            {beeld.images &&
                <div style={{ float: "right", width: "220px" }}>
                    <Images images={beeld.images} />
                </div>
            }

            <h2>{this.state.beeld.name}</h2>

            <div>
                <b>Steensoort:</b> {this.state.beeld.rock_type}
            </div>
            <div>
                <b>Afmetingen:</b> H x B x D (cm) {this.state.beeld.height} x {this.state.beeld.width} x {this.state.beeld.depth}
            </div>
            <div>
                <b>Jaar:</b> {this.state.beeld.year}
            </div>

            <ReactMarkdown>{this.state.beeld.description}</ReactMarkdown>

        </main>
    }
}

export default (props: any) => <Component {...useParams()} {...props} />
