import axios from "axios";
import * as React from "react";
import ReactMarkdown from 'react-markdown'
import { useParams } from "react-router-dom";
import { Images } from "../images/images"


interface Props {
    expositieId: string

}
interface State {
    expositie: IExpositie | undefined
}

class Component extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            expositie: undefined
        }
    }

    update(expositie: IExpositie) {
        this.setState({
            expositie: expositie,
        })
    }

    componentDidMount() {
        axios.get('/assets/exposities/expositions.json')
            .then(response => {
                this.update(response.data.expositions.find((expositie: IExpositie) => expositie.id === this.props.expositieId))
            })
    }

    render() {
        if (this.state.expositie === undefined) {
            return <div>not found</div>
        }

        let expositie = this.state.expositie

        return <main style={{ padding: "1rem" }}>

            {expositie.images &&
                <div style={{ float: "right", width: "220px" }}>
                    <Images images={expositie.images} />
                </div>
            }

            <h2>{expositie.name}</h2>

            <div>
                <b>Datum:</b> {expositie.date}
            </div>
            {expositie.from &&
                <div>
                    <b>Tijd:</b> {expositie.from} tot {expositie.till}
                </div>
            }

            <div>
                <b>Adres:</b> {expositie.address}
            </div>


            <ReactMarkdown>{expositie.description}</ReactMarkdown>

        </main >
    }
}

export default (props: any) => <Component {...useParams()} {...props} />