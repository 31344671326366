import * as React from "react";
import ReactMarkdown from 'react-markdown'
import { Link } from "react-router-dom";
import './teaser.css';


interface Props {
    entity: IBeeld;
}

interface State { }

export class DesktopBeeld extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return <div>
            <h2>
                <Link
                    style={{ display: "block", margin: "1rem 0" }}
                    to={`/beeld/${this.props.entity.id}`}
                    key={this.props.entity.id}
                >
                    {this.props.entity.name}
                </Link>
            </h2>
            {this.props.entity.images && <div className="image"><img src={this.props.entity.images[0].thumb} alt={this.props.entity.name} width="90" /></div>}
            <div>
                <strong>Steensoort:</strong> {this.props.entity.rock_type}<br />
                <strong>Afmetingen:</strong> H x B x D (cm) = {this.props.entity.height} x {this.props.entity.width} x {this.props.entity.depth}<br />
                <strong>Jaar:</strong> {this.props.entity.year}
            </div>


            <ReactMarkdown>{this.props.entity.description}</ReactMarkdown>
            <div className='read-more'>
                <Link
                    to={`/beeld/${this.props.entity.id}`}
                    key={`${this.props.entity.id}-read-more`}
                >
                    Read more
                </Link>
            </div>
            <div className='clear'></div>

        </div>
    }
}
