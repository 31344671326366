import * as React from "react";
import { Outlet, Link, NavLink } from "react-router-dom";
import { BrowserView, MobileView } from 'react-device-detect';
import './index.css';
import { MobileMenuComponent } from './mobile-menu'


interface Props { }
interface State { }

class Component extends React.Component<Props, State> {
    render() {
        return <>
            <BrowserView>
                <div id="desktop-page">
                    <div id="desktop-menu">
                        <div className="desktop-menu-items">
                            <ul>
                                <li><NavLink to="/">home</NavLink></li>
                                <li><NavLink to="/biografie">biografie</NavLink></li>
                                <li><NavLink to="/beelden">beelden</NavLink></li>
                                <li><NavLink to="/exposities">exposities</NavLink></li>
                            </ul>
                        </div>
                        <div id="desktop-site-info">
                            <span><Link to="/">Stone Sculpture</Link></span>
                        </div>
                    </div>
                    <div id="desktop-content">
                        <Outlet />
                    </div>
                </div>
            </BrowserView>
            <MobileView>
                <div id="mobile-page">
                    <MobileMenuComponent />
                    <main id="mobile-content">
                        <Outlet />
                    </main>
                </div>
            </MobileView>
        </>
    }
}

export default Component;
