import * as React from "react";
import { NavLink } from "react-router-dom";
import { scaleRotate as Menu } from 'react-burger-menu'
import './mobile-menu.css';


interface Props { }

interface State {
    isOpen: boolean
    menuOpen: boolean
}

export class MobileMenuComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            menuOpen: false,
            isOpen: false
        }
    }
    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange(state: State) {
        this.setState({ menuOpen: state.isOpen })
    }

    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu() {
        this.setState({ menuOpen: false })
    }

    // This can be used to toggle the menu, e.g. when using a custom icon
    // Tip: You probably want to hide either/both default icons if using a custom icon
    // See https://github.com/negomi/react-burger-menu#custom-icons
    toggleMenu() {
        this.setState(state => ({ menuOpen: !state.menuOpen }))
    }

    render() {
        return (
            <>
                <div className="mobile-header">
                    <h1>StoneSculpture</h1>
                </div>
                <div>
                    <Menu
                        isOpen={this.state.menuOpen}
                        onStateChange={(state: State) => this.handleStateChange(state)}
                        pageWrapId={"mobile-content"}
                        outerContainerId={"mobile-page"}
                    >
                        <NavLink onClick={() => this.closeMenu()} id="home" className="menu-item" to="/">Home</NavLink>
                        <NavLink onClick={() => this.closeMenu()} id="biografie" className="menu-item" to="/biografie">Biografie</NavLink>
                        <NavLink onClick={() => this.closeMenu()} id="beelden" className="menu-item" to="/beelden">Beelden</NavLink>
                        <NavLink onClick={() => this.closeMenu()} id="exposities" className="menu-item" to="/exposities">Exposities</NavLink>
                    </Menu>
                </div>
            </>
        )
    }
}
