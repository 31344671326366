import * as React from "react";
import { BrowserView, MobileView } from 'react-device-detect';
import { DesktopBeeld } from "./desktop-beeld";
import { MobileBeeld } from "./mobile-beeld";

interface Props {
    beeld?: IBeeld;
    other?: string
}

interface State { }

export class Teaser extends React.Component<Props, State> {
    render() {
        if (this.props.beeld !== undefined) {
            return <>
                <BrowserView>
                    <DesktopBeeld entity={this.props.beeld} />
                </BrowserView>
                <MobileView>
                    <MobileBeeld entity={this.props.beeld} />
                </MobileView>
            </>
        }

        return <div>No teaser implementation found for {this.props.other}</div>
    }
}
