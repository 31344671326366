import axios from "axios";
import React from 'react';




class SculptureService {
    /**
     * TODO: expire the cache!
     */
    private static _instance: SculptureService;

    public static get Instance() {
        return this._instance || (this._instance = new this());
    }

    async getAll(): Promise<IBeeld[]> {
        return new Promise((resolve, reject) => {
            axios.get('/assets/beelden/sculptures.json')
                .then(response => response.data.sculptures)
                .then(data => {
                    resolve(data);
                });
        });
    }

    async getSculpture(id: string): Promise<IBeeld | undefined> {
        return new Promise((resolve, reject) => {
            this.getAll().then(sculptures => {
                resolve(sculptures.find((sculpture: any) => sculpture.id === id))
            })
        });
    }
}

export const sculptureService = SculptureService.Instance;
