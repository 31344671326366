import * as React from "react";
import { Link } from "react-router-dom";
import { sculptureService } from "../services/sculptures"

interface Props { }
interface State {
    beelden: IBeeld[]
}

class Component extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            beelden: []
        }
    }

    componentDidMount() {
        sculptureService.getAll().then(response => {
            this.setState({ beelden: response })
        });
    }

    render() {

        return <main style={{ padding: "1rem 0" }}>
            <h2>Beelden van Jos Conijn</h2>

            <table>
                <thead>
                    <tr>
                        <th>Afbeeldingen</th>
                        <th>Naam</th>
                        <th>Steensoort</th>
                        <th>Afmetingen</th>
                        <th>Jaar</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.beelden.map(beeld => (
                        <tr key={beeld.id}>
                            <td>{beeld.images && beeld.images[0] && <img src={beeld.images[0].thumb} alt={beeld.name} width="90" />}</td>
                            <td><Link to={`/beeld/${beeld.id}`}>{beeld.name}</Link></td>
                            <td>{beeld.rock_type}</td>
                            <td>H x B x D (cm) = {beeld.height} x {beeld.width} x {beeld.depth}</td>
                            <td>{beeld.year}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </main>
    }
}

export default Component;


