import * as React from "react";
import ReactMarkdown from 'react-markdown'
import { Link } from "react-router-dom";
import './teaser.css';
import './mobile-beeld.css';


interface Props {
    entity: IBeeld;
}

interface State {
    featureImage: string
}

export class MobileBeeld extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        if (this.props.entity.featured_image !== null) {
            this.state = {
                featureImage: this.props.entity.featured_image,
            }
        } else {
            this.state = {
                featureImage: "",
            }
        }
    }
    render() {
        return <div className="teaser-tile">
            <Link
                to={`/beeld/${this.props.entity.id}`}
                key={this.props.entity.id}
            >
                <img src={this.state.featureImage} />
            </Link>
            <div className="teaser-caption">
                <p>{this.props.entity.name}</p>
            </div>
        </div>
    }
}
