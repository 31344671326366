import * as React from "react";
import ReactMarkdown from 'react-markdown'

interface Props { }
interface State { }

class Component extends React.Component<Props, State> {

    render() {
        let biografie = `
Jos Conijn(Wormer 1955)

HBO opleiding en werkzaam in de techniek, had als hobby schilderen met olie - en waterverf.
Jos stootte zich in 2002 aan z'n eerste steen en besloot hierop nader kennis te maken met de mogelijkheden van steen.

Resultante hiervan: Penselen ingeruild voor beitels en hamer.

**STONE SCULPTURE:**

> We leven in een tijd van beeldvorming!

De 'derde' dimensie in beeldhouwwerk geeft mij de extra uitdaging om een 'verdiepend' beeld te maken.

Steen de oermaterie van ons bestaan, heeft het eerste tijdperk al op zijn naam.

Steen wacht al vanaf de oertijd, om als een beeld te worden gevormd in onze tijd!

Steen bewerkt door hamer, beitel en jouw keur, is gepolijst verrassend van kleur.

### Cursus

- In 2002 gestart met cursus kennismaking met beeldhouwen in steen o.l.v Reina Ramakers
- In 2004 vervolg cursus portretboetseren o.l.v. Naomie Elburg
- In 2005 Workshop beeldhouwen o.l.v. Kees Verwey

### Vormgeving

Beeldwerk: zowel figuratief als ook abstract.`

        return <main style={{ padding: "1rem 0" }}>
            <h2>Biografie</h2>

            <ReactMarkdown children={biografie} />
        </main>
    }
}

export default Component;