import React from 'react';
import { Teaser } from "../teaser/teaser";
import { sculptureService } from "../services/sculptures"


interface Props { }

interface State {
    beelden: IBeeld[]
}

class Component extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            beelden: []
        }
    }

    componentDidMount() {
        sculptureService.getAll().then(response => {
            this.setState({ beelden: response })
        });
    }

    render() {

        return <main>
            {this.state.beelden.map(beeld => (
                <Teaser key={beeld.id} beeld={beeld}></Teaser>
            ))}
        </main>
    }
}

export default Component;