import axios from "axios";
import * as React from "react";
import { Link } from "react-router-dom";


interface Props { }

interface State {
    expositions: IExpositie[]
}

class Component extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            expositions: []
        }
    }

    componentDidMount() {
        axios.get('/assets/exposities/expositions.json')
            .then(response => this.setState({ expositions: response.data.expositions }));
    }

    render() {
        return <main style={{ padding: "1rem 0" }}>
            <h2>Exposities van Jos Conijn</h2>

            <table>
                <thead>
                    <tr>
                        <th>Naam</th>
                        <th>Datum</th>
                        <th>Van</th>
                        <th>Tot</th>
                    </tr>
                </thead>
                <tbody>
                    {this.state.expositions.map(expositie => (
                        <tr key={expositie.id}>
                            <td><Link to={`/expositie/${expositie.id}`}>{expositie.name}</Link></td>
                            <td>{expositie.date}</td>
                            <td>{expositie.from}</td>
                            <td>{expositie.till}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </main>
    }
}

export default Component;
